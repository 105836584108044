/* eslint-disable no-var */
/* eslint-disable prefer-template */
window.meso = window.meso || {};
window.meso.adblock = window.meso.adblock || {};
window.meso.adblock.enabled = false;

Object.defineProperty(window, 'adblock', {
  get() {
    // eslint-disable-next-line no-console
    console.warn('window.adblock has been deprecated, Please use window.meso.adblock.enabled instead');

    var adblockURL = window.location.href.split('?')[0];

    window.meso.logging = window.meso.logging || [];
    window.meso.logging.push({ logs: {
        type: 'warn',
        modulename: 'expads-blocked',
        message: 'GLOBAL_ADBLOCK_KEY_DEPRECATED',
        details: {
          description: 'window.adblock used in: ' + adblockURL
        }
    } });

    return window.meso.adblock.enabled;
  },

  // make configurable to allow adblock property to be overwritten (false by default)
  configurable: true
});
